.establishment {
  position: relative;

  header {
    background: #36454f no-repeat center;
    background-size: cover;
    height: 340px;
    width: 100%;
    display: flex;

    .content {
      margin: auto;
      display: flex;
      flex-direction: column;
      color: #fff;

      .title {
        letter-spacing: 2px;
        font-style: normal;
        text-transform: uppercase;
        font-size: 30px;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 15px;
        text-align: center;
        line-height: 0.9;

        @media (max-width: 700px) {
          font-size: 24px;
        }
      }

      .address {
        font-size: 20px;
        text-align: center;
        line-height: 0.9;
        letter-spacing: 0.5px;

        @media (max-width: 700px) {
          font-size: 15px;
        }
      }

      .logo {
        border-radius: 50%;
        border: 4px solid rgba(220, 220, 220, 0.35);
        margin: 10px auto;
      }

      .box-open-closed {
        text-align: center;

        .button {
          background: #fff;
          border: none;
          padding: 7px 15px;
          font-size: 14px;
          font-weight: bold;
          border-radius: 3px;
          width: 200px;
          margin: 5px auto;

          span {
            margin: 15px;
          }
        }

        .open {
          color: #008b00;
        }

        .closed {
          color: #ff0000;
        }

        button {
          color: #777;
        }

        button:hover {
          color: #8888;
          scale: 1.05;
          transition: all ease 1s;
          border: 2px solid #8888;
        }
      }
    }
  }

  #nav-bar {
    background: #fff;
    max-height: 67px;
    position: sticky !important;
    top: 40px !important;
    z-index: 1000;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    max-width: 100%;

    .nav-content {
      display: flex;

      .box-buttons {
        min-height: 100%;
        display: flex;
        align-items: center;

        @media (max-width: 992px) {
          display: none;
        }

        button {
          background: none;
          // bo
          border: #fff;
        }

        i {
          font-size: 24px;
          cursor: pointer;
        }
      }
    }

    ul {
      display: flex;
      height: 100%;
      max-height: 100%;
      // overflow-x: hidden;
      overflow-y: hidden;

      li {
        list-style: none;
        margin: 0 5px;
        display: flex;
        cursor: pointer;

        a {
          color: #777;
          padding: 15px;
          font-weight: bold;
          font-size: 21px;
          outline: none;
          white-space: nowrap;

          @media (max-width: 992px) {
            font-size: 16px;
            padding: 5px;
          }
        }

        a:focus {
          text-decoration: none;
        }

        a:hover {
          scale: 1.05;
          transition: 1s all ease;
        }
      }
    }

    .is-active {
      color: #000;
      border-bottom: 5px solid #000;
    }
  }

  .top-bar {
    background: #36454f;
    padding: 5px 20px;
    position: sticky;
    top: 0;
    height: 40px;
    width: 100%;
    z-index: 1000;

    .content {
      display: flex;
      justify-content: space-between;

      .search-button {
        display: none;
        color: #fff;
        margin: auto 10px auto 0;
        cursor: pointer;
        font-size: 15px;

        @media (max-width: 600px) {
          display: block;
        }
      }

      .box-button {
        button {
          border: none;
          background: none;
          margin: 0 10px;
          border-radius: 5px;
          font-weight: 600;
          font-size: 18px;
          color: #fff;

          @media (max-width: 600px) {
            font-size: 10px;
          }
        }

        button:hover {
          background: #fff;
          color: #36454f;
          transition: 0.5s all ease;
        }
      }

      .input {
        background: #fff;
        border: none;
        border-radius: 5px;
        padding-left: 10px;
        max-height: 100%;
        border: 1px solid #777;

        @media (max-width: 600px) {
          display: none;
        }
      }

      .input:focus {
        outline: none;
      }
    }
  }

  .box-categories {
    padding-bottom: 150px;

    .category {
      background: #fff;
      margin-top: 20px;
      border-radius: 5px;
      padding: 10px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      display: flex;
      flex-wrap: wrap;

      .category-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 3px solid rgba(105, 105, 105, 0.13);

        p {
          font-size: 21px;
          font-weight: 700;
        }

        // padding: 10px;
        width: 100%;
      }

      .category-items {
        border-bottom: 0.5px solid #dcdcdc;
        width: 46%;
        min-width: 300px;
        flex-grow: 1;
        // margin: 10px;
        cursor: pointer;
        // padding: 10px;
        border-radius: 5px;
        margin-top: 5px;
        display: flex;
        max-width: 46%;

        @media (max-width: 780px) {
          // min-width: 100px;
          width: 100px;
          max-width: 100%;
          font-size: 10px;

          .h5 {
            font-size: 17px;
            font-weight: bold;
          }

          .category-image {
            margin: 10px;

            img {
              border-radius: 6px !important;
              width: 120px !important;
              height: 120px !important;
            }
          }

          .category-title {
            display: flex !important;
            align-items: center !important;
            justify-content: space-between !important;
            border-bottom: 3px solid rgba(105, 105, 105, 0.13) !important;

            p {
              font-size: 17px !important;
              font-weight: 700 !important;
            }

            padding: 3px !important;
            width: 100% !important;
          }

        }

        .price {
          color: #008000;
          font-weight: bold;
        }

        .category-text {
          width: 75%;
          color: #36454f;
          border: 2px solid transparent;

          .sub-title {
            font-weight: 400;
            font-size: 16px;
          }

          .description {
            color: #36454f;
            font-size: 14px;
            font-weight: lighter;
            letter-spacing: 0.1px;
            line-height: 1.4;
          }
        }

        .category-image {
          margin: 10px;

          img {
            border-radius: 6px;
            width: 150;
            height: 150px;
          }
        }
      }

      .category-items:hover {
        background-image: linear-gradient(to left,
            rgba(169, 169, 169, 0.2),
            rgba(169, 169, 169, 0));
      }
    }
  }

  .establishment-footer {
    margin-top: 60px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #777;

    a {
      margin: 5px;
    }
  }
}

.wrapper {
  width: 95%;
  max-width: 1170px;
  margin: 0 auto;
}