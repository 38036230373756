.schedule-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.modal-header h2 {
    font-size: 20px;
    font-weight: 600;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.modal-body {
    margin-top: 15px;
}

.day-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.day-checkbox {
    margin-right: 10px;
}

.day-name {
    flex: 1;
    font-size: 16px;
}

.separator {
    font-size: 14px;
    margin: 0 5px;
}

.time-input {
    width: 80px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.time-input:disabled {
    background: #f5f5f5;
    color: #999;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.cancel-button,
.save-button {
    height: 35px;
    padding: 5px 15px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.cancel-button {
    background: #ccc;
    border: none;
    color: #333;
}

.cancel-button:hover {
    background: #bbb;
}

.save-button {
    background: #008000;
    border: none;
    color: #fff;
}

.save-button:hover {
    background: #006400;
}