.whatsapp-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    width: 90%;
    max-width: 450px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.modal-header h2 {
    font-size: 20px;
    font-weight: 600;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.modal-body {
    margin-top: 15px;
}

.phone-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
}

.generate-button,
.status-button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.generate-button {
    background: #008000;
    color: white;
}

.generate-button:hover {
    background: #006400;
}

.status-button {
    background: #007bff;
    color: white;
}

.status-button:hover {
    background: #0056b3;
}

.qr-section {
    margin-top: 20px;
}

.qr-code {
    width: 80%;
    max-width: 250px;
    margin-top: 10px;
}

.status-text {
    font-size: 16px;
    margin-top: 10px;
}

.modal-footer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.cancel-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    background: #ccc;
}

.cancel-button:hover {
    background: #bbb;
}