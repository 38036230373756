.orders-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f5f5f5;
  position: relative;
  padding-bottom: 60px;
}

.orders-header {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  position: sticky;
  top: 0;
  z-index: 10;

  .back-button {
    background: none;
    border: none;
    font-size: 20px;
    margin-right: 16px;
    cursor: pointer;
    color: #333;
  }

  h1 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.orders-list {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.no-orders {
  text-align: center;
  padding: 32px;
  color: #666;
  font-size: 16px;
}

.order-card {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;

  .order-title {
    h2 {
      font-size: 18px;
      font-weight: 600;
      margin: 0 0 4px 0;
    }

    .order-date {
      font-size: 14px;
      color: #666;
    }
  }

  .status-badge {
    background-color: #767676;
    color: white;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
  }
}

.order-items {
  border: 1px solid #eee;
  border-radius: 4px;
  margin-bottom: 16px;
  max-height: 120px;
  overflow-y: auto;

  .item {
    padding: 12px;
    border-bottom: 1px solid #eee;
    font-size: 15px;

    &:last-child {
      border-bottom: none;
    }
  }
}

.order-total {
  font-size: 18px;
  margin-bottom: 16px;
}

.order-actions {
  display: flex;
  flex-direction: column;
  gap: 8px;

  button {
    padding: 12px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .details-button {
    background-color: white;
    border: 1px solid #5b9923;
    color: #5b9923;
  }

  .repeat-button {
    background-color: #5b9923;
    border: none;
    color: white;
  }
}

.bottom-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  border-top: 1px solid #e0e0e0;

  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 12px;
    color: #666;

    &.active {
      color: #5b9923;
    }

    i {
      display: block;
      width: 24px;
      height: 24px;
      margin-bottom: 4px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .home-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23666'%3E%3Cpath d='M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z'/%3E%3C/svg%3E");
    }

    .orders-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23d12017'%3E%3Cpath d='M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z'/%3E%3C/svg%3E");
    }

    .cart-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23666'%3E%3Cpath d='M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z'/%3E%3C/svg%3E");
    }
  }
}